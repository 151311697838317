/**
 * 全站权限配置
 *
 */
import router from '@/router/index'
import store from '@/store'
import { validatenull } from '@/util/validate'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false })

/**
 * 导航守卫，相关内容可以参考:
 * https://router.vuejs.org/zh/guide/advanced/navigation-guards.html
 */
router.beforeEach(async (to, from, next) => {
  // 缓冲设置
  if (
    to.meta.keepAlive === true &&
    store.state.tags.tagList.some((ele) => {
      return ele.value === to.fullPath
    })
  ) {
    to.meta.$keepAlive = true
  } else {
    NProgress.start()
    if (to.meta.keepAlive === true && validatenull(to.meta.$keepAlive)) {
      to.meta.$keepAlive = true
    } else {
      to.meta.$keepAlive = false
    }
  }
  const meta = to.meta || {}
  if (store.getters.access_token) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      // NOTE: 当用户角色不存在时，会存在无限请求用户信息接口的问题
      if (store.getters.roles.length === 0) {
        try {
          await store.dispatch('GetUserInfo')
          await store.dispatch('GetMenu', { type: true, id: -1 })
          const value = to.query.src || to.fullPath
          const label = to.query.name || to.name
          if (
            meta.isTab !== false &&
            !validatenull(value) &&
            !validatenull(label)
          ) {
            store.commit('ADD_TAG', {
              label: label,
              value: value,
              params: to.params,
              query: to.query
              // group: router.$avueRouter.group || []
            })
          }
          next({ ...to })
        } catch (error) {
          store.dispatch('FedLogOut').then(() => {
            next(`/login?redirect=${to.fullPath}`)
          })
        }
        // store
        //   .dispatch('GetUserInfo')
        //   .then(() => {
        //     const value = to.query.src || to.fullPath
        //     const label = to.query.name || to.name
        //     store.commit('ADD_TAG', {
        //       label: label,
        //       value: value,
        //       params: to.params,
        //       query: to.query
        //       // group: router.$avueRouter.group || []
        //     })
        //     next()
        //   })
        //   .catch(() => {
        //     store.dispatch('FedLogOut').then(() => {
        //       next({ path: '/login' })
        //     })
        //   })
      } else {
        const value = to.query.src || to.fullPath
        const label = to.query.name || to.name

        // 针对外链跳转
        if (value.includes('http', 'https')) {
          NProgress.done()
          window.open(value, '_blank')
          return
        }

        if (
          meta.isTab !== false &&
          !validatenull(value) &&
          !validatenull(label)
        ) {
          store.commit('ADD_TAG', {
            label: label,
            value: value,
            params: to.params,
            query: to.query
            // group: router.$avueRouter.group || []
          })
        }
        next()
      }
    }
  } else {
    if (meta.isAuth === false) {
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`)
    }
  }
})

router.afterEach((to) => {
  NProgress.done()
  let webisteInfo = store.getters.website
  const title = to.name
    ? `${to.name}——${webisteInfo.title}`
    : `${webisteInfo.title}`
  document.title = title
})
