import { createRouter, createWebHashHistory } from 'vue-router'
import routes from '@/router/routes'
// import AvueRouter from './avue-router'
// import store from '@/store/index'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// AvueRouter.install(router, store)
// router.$avueRouter.formatRoutes(Store.state.user.menu, true)

export default router
