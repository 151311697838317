import axios from './axios'

export default function request(api, method = 'GET', data, timeout) {
  return new Promise((resolve, reject) => {
    if (method == 'post' || method == 'POST') {
      axios
        .post(api, data, { timeout: timeout })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } else if (method == 'GET' || method == 'get') {
      axios
        .get(api, { params: data, timeout: timeout })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } else if (method == 'put' || method == 'PUT') {
      axios
        .put(api, data, { timeout: timeout })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    } else if (
      method == 'del' ||
      method == 'delete' ||
      method == 'Del' ||
      method == 'Delete' ||
      method == 'DELETE'
    ) {
      axios
        .delete(api, { data: data })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    }
  })
}
