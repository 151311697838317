import Layout from '@/components/Layout'

var routes = [
  {
    path: '/login',
    name: '登录页',
    component: () => import(/* webpackChunkName: "views" */ '@/views/login'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/wel',
    component: Layout,
    redirect: '/wel/index',
    children: [
      {
        path: 'index',
        name: '首页',
        component: () => import(/* webpackChunkName: "views" */ '@/views/home'),
        meta: {
          keepAlive: false,
          isTab: true,
          isAuth: true
        }
      }
    ]
  },
  {
    path: '/',
    name: '主页',
    redirect: '/wel'
  },
  {
    path: '/orderDetail',
    component: () => import('@/views/orderManager/orderDetail'),
    name: '订单详情',
    meta: {
      keepAlive: false,
      isTab: false
    }
  },
  {
    path: '/refundOrderDetail',
    component: () =>
      import('@/views/orderManager/refundOrder/refundOrderDetail.vue'),
    name: '退票单详情',
    meta: {
      keepAlive: false,
      isTab: false
    }
  },
  {
    path: '/orderChangeDetail',
    component: () =>
      import('@/views/orderManager/changeOrder/orderChangeDetail.vue'),
    name: '改签单详情',
    meta: {
      keepAlive: false,
      isTab: false
    }
  },

  // {
  //   path: '/404',
  //   component: () =>
  //     import(/* webpackChunkName: "page" */ '@/components/error-page/404'),
  //   name: '404',
  //   meta: {
  //     keepAlive: true,
  //     isTab: false,
  //     isAuth: true
  //   }
  // },
  // {
  //   path: '/403',
  //   component: () =>
  //     import(/* webpackChunkName: "page" */ '@/components/error-page/403'),
  //   name: '403',
  //   meta: {
  //     keepAlive: true,
  //     isTab: false,
  //     isAuth: false
  //   }
  // },
  // {
  //   path: '/500',
  //   component: () =>
  //     import(/* webpackChunkName: "page" */ '@/components/error-page/500'),
  //   name: '500',
  //   meta: {
  //     keepAlive: true,
  //     isTab: false,
  //     isAuth: false
  //   }
  // },
  {
    path: '/:pathMatch(.*)', // 不识别的path自动匹配404
    component: () =>
      import(/* webpackChunkName: "page" */ '@/components/error-page/404')
  }
]

export default routes
