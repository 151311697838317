import request from '@/util/request'
import { defineStore } from 'pinia'

export const useCommonV3Store = defineStore('commonV3', {
  state: () => {
    return {
      airlineList: []
    }
  },
  actions: {
    getAirlineList() {
      request('/config/airline/getallairline', 'GET').then((res) => {
        this.airlineList = res.data
      })
    }
  }
})
