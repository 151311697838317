import { getStore, setStore } from '@/util/store'
import { isURL, validatenull } from '@/util/validate'
import {
  getUserInfo,
  loginByUsername,
  loginByMobile,
  logout,
  refreshToken,
  verifyLogin,
  delCache
} from '@/api/login'
import { ElMessageBox } from 'element-plus'
import { deepClone, encryption } from '@/util/util'
import webiste from '@/assets/const/website'
import { getMenu } from '@/api/admin/menu'
import urlencode from 'urlencode'
import { watermark } from 'watermark-dom'
import Store from '../index'
import createRoutes from '@/router/createRoutes'

import router from '@/router'

function addPath(ele, first) {
  const menu = webiste.menu
  const propsConfig = menu.props
  const propsDefault = {
    label: propsConfig.label || 'name',
    path: propsConfig.path || 'path',
    icon: propsConfig.icon || 'icon',
    children: propsConfig.children || 'children'
  }
  const icon = ele[propsDefault.icon]
  ele[propsDefault.icon] = validatenull(icon) ? menu.iconDefault : icon
  const isChild =
    ele[propsDefault.children] && ele[propsDefault.children].length !== 0
  if (!isChild) ele[propsDefault.children] = []
  if (!isChild && first && !isURL(ele[propsDefault.path])) {
    ele[propsDefault.path] = ele[propsDefault.path] + '/index'
  } else {
    ele[propsDefault.children].forEach((child) => {
      addPath(child)
    })
  }
}

const user = {
  state: {
    watermark: watermark,
    userInfo: {},
    permissions: {},
    roles: [],
    menu:
      getStore({
        name: 'menu'
      }) || [],
    menuAll: [],
    access_token:
      getStore({
        name: 'access_token'
      }) || '',
    refresh_token:
      getStore({
        name: 'refresh_token'
      }) || '',
    loginTime: getStore({ name: 'login_time' }) || null
  },
  actions: {
    // 根据用户名登录
    LoginByUsername({ commit, dispatch, state }, userInfo) {
      const user = encryption({
        data: userInfo,
        key: '1234567890123456',
        param: ['password']
      })
      return new Promise((resolve, reject) => {
        loginByUsername(
          user.username,
          user.password,
          user.code,
          user.randomStr,
          state.loginTime,
          user.forced
        )
          .then((response) => {
            if (response.status === 401 && response.data.code === 303) {
              ElMessageBox.confirm(response.data.msg, '错误', {
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then(() => {
                  dispatch('DelCache', userInfo.username).then(() => {
                    dispatch('LoginByUsername', {
                      ...userInfo,
                      forced: 'Y'
                    }).then(() => {
                      router.push({
                        path: Store.getters.tagWel.value
                      })
                    })
                  })
                })
                .catch(() => {
                  reject()
                })
            } else {
              const data = response.data
              commit('SET_ACCESS_TOKEN', urlencode(data.access_token))
              commit('SET_REFRESH_TOKEN', data.refresh_token)
              commit('CLEAR_LOCK')
              commit('LOGIN_TIME', data.user_info.loginTime)
              resolve()
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 根据用户名、手机验证码登录
    LoginByPhone({ commit, dispatch, state }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByMobile(userInfo.username, userInfo.code, state.loginTime)
          .then((response) => {
            if (response.status === 401 && response.data.code === 303) {
              ElMessageBox.confirm(response.data.msg, '错误', {
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then(() => {
                  dispatch('DelCache', userInfo.username).then(() => {
                    dispatch('LoginByPhone', userInfo).then(() => {
                      router.push({
                        path: Store.getters.tagWel.value
                      })
                    })
                  })
                })
                .catch(() => {
                  reject()
                })
            } else {
              const data = response.data
              commit('SET_ACCESS_TOKEN', urlencode(data.access_token))
              commit('SET_REFRESH_TOKEN', data.refresh_token)
              commit('CLEAR_LOCK')
              commit('LOGIN_TIME', data.user_info.loginTime)
              resolve()
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 验证登录状态 -- 暂无使用
    VerifyLogin({ state }, userInfo) {
      return new Promise((resolve, reject) => {
        verifyLogin({
          username: userInfo.username,
          loginTime: state.loginTime
        }).then((response) => {
          if (response.data.code === 303) {
            ElMessageBox.confirm(response.data.msg, '错误', {
              showCancelButton: true,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                delCache(userInfo.username).then(() => {
                  resolve()
                })
              })
              .catch(() => {
                reject()
              })
          } else {
            resolve()
          }
        })
      })
    },
    // 强制登录时需要清除其他地方已登录的信息
    DelCache({ commit }, username) {
      return new Promise((resolve, reject) => {
        delCache(username)
          .then(() => {
            commit('SET_ACCESS_TOKEN', '')
            commit('SET_REFRESH_TOKEN', '')
            commit('CLEAR_LOCK')
            commit('LOGIN_TIME', null)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((res) => {
            const data = res.data.data || {}
            commit('SET_USER_INFO', data.sysUser)
            commit('SET_ROLES', data.roles || [])
            commit('SET_PERMISSIONS', data.permissions || [])
            resolve(data)
          })
          .catch(() => {
            reject()
          })
      })
    },
    // 刷新token
    RefreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refresh_token)
          .then((response) => {
            const data = response.data
            commit('SET_ACCESS_TOKEN', urlencode(data.access_token))
            commit('SET_REFRESH_TOKEN', data.refresh_token)
            commit('CLEAR_LOCK')
            commit('LOGIN_TIME', data.user_info.loginTime)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_MENU', [])
            commit('SET_PERMISSIONS', [])
            commit('SET_USER_INFO', {})
            commit('SET_ACCESS_TOKEN', '')
            commit('SET_REFRESH_TOKEN', '')
            commit('SET_ROLES', [])
            commit('DEL_ALL_TAG')
            commit('CLEAR_LOCK')
            // commit("LOGIN_TIME", null);  不清除本地loginTime,留给后端解决两个浏览器checktoken后不把另外用户挤掉
            state.watermark.remove()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 注销session
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_MENU', [])
        commit('SET_PERMISSIONS', [])
        commit('SET_USER_INFO', {})
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_REFRESH_TOKEN', '')
        commit('SET_ROLES', [])
        commit('DEL_ALL_TAG')
        commit('CLEAR_LOCK')
        // commit("LOGIN_TIME", null);
        resolve()
      })
    },
    // 获取系统菜单
    GetMenu({ commit }, obj) {
      return new Promise((resolve) => {
        getMenu(obj.id).then((res) => {
          const data = res.data.data || []
          let menu = deepClone(data)
          menu.forEach((ele) => {
            addPath(ele)
          })
          let type = obj.type
          commit('SET_MENU', { type, menu })
          // 动态添加路由
          createRoutes()
          resolve(menu)
        })
      })
    }
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token
      setStore({
        name: 'access_token',
        content: state.access_token
        // type: 'session'
      })
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
      state.refresh_token = rfToken
      setStore({
        name: 'refresh_token',
        content: state.refresh_token
        // type: 'session'
      })
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      setStore({
        name: 'userInfo',
        content: state.userInfo
      })
      // 系统增加水印
      state.watermark.init({
        watermark_txt: state.userInfo.username,
        watermark_alpha: 0.06,
        watermark_width: 180,
        monitor: false // monitor是否监控，true：不可删除水印，false：可删水印
      })
    },
    SET_MENU: (state, params = {}) => {
      let { menu, type } = params
      if (type !== false) state.menu = menu
      setStore({
        name: 'menu',
        content: menu
        // type: 'session'
      })
    },
    SET_MENU_ALL: (state, menuAll) => {
      state.menuAll = menuAll
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      const list = {}
      for (let i = 0; i < permissions.length; i++) {
        list[permissions[i]] = true
      }
      state.permissions = list
    },
    LOGIN_TIME: (state, loginTime) => {
      state.loginTime = loginTime
      setStore({
        name: 'login_time',
        content: state.loginTime
      })
    }
  }
}
export default user
