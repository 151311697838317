import request from '@/util/axios'
import store from '@/store'
import qs from 'qs'
import { getStore, setStore } from '@/util/store.js'
import website from '@/assets/const/website.js'
import { ElMessageBox } from 'element-plus'

const scope = 'server'

export const loginByUsername = (
  username,
  password,
  code,
  randomStr,
  loginTime,
  forced
) => {
  const grant_type = 'password'
  let dataObj = qs.stringify({ username: username, password: password })

  let basicAuth = 'Basic ' + window.btoa(website.formLoginClient)

  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })

  return request({
    url: '/auth/oauth2/token',
    headers: {
      isToken: false,
      Authorization: basicAuth
    },
    method: 'post',
    params: {
      randomStr,
      code,
      grant_type,
      scope,
      loginTime: String(loginTime),
      forced: forced ? forced : 'N'
    },
    data: dataObj
  })
}

export const loginByMobile = (username, code, loginTime) => {
  const grant_type = 'app'

  let basicAuth = 'Basic ' + window.btoa(website.smsLoginClient)

  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })

  return request({
    url: '/auth/oauth2/token',
    headers: {
      isToken: false,
      Authorization: basicAuth
    },
    method: 'post',
    params: {
      username: username.toLowerCase(),
      code: code,
      loginTime: String(loginTime),
      grant_type,
      scope
    }
  })
}

export const refreshToken = (refresh_token) => {
  const grant_type = 'refresh_token'
  // 获取当前选中的 basic 认证信息
  let basicAuth = getStore({ name: 'basicAuth' })

  return request({
    url: '/auth/oauth2/token',
    headers: {
      isToken: false,
      Authorization: basicAuth
    },
    method: 'post',
    params: { refresh_token, grant_type, scope }
  })
}

export const verifyLogin = ({ username, loginTime }) => {
  console.log(username, loginTime, 'username, loginTime')
  return request({
    url: '/admin/loginManager/verifyLogin',
    method: 'get',
    params: { username: username.toLowerCase(), loginTime: String(loginTime) }
  })
}
export const delCache = (username) => {
  return request({
    url: '/admin/loginManager/delCache',
    method: 'get',
    params: { username: username.toLowerCase() }
  })
}

export const getUserInfo = () => {
  return request({
    url: '/admin/user/info',
    method: 'get'
  })
}

export const logout = () => {
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}

/**
 * 校验令牌，若有效期小于半小时自动续期
 * @param refreshLock
 */
export const checkToken = (refreshLock, $store) => {
  const token = store.getters.access_token
  // 获取当前选中的 basic 认证信息
  let basicAuth = getStore({ name: 'basicAuth' })

  request({
    url: '/auth/token/check_token',
    headers: {
      isToken: false,
      Authorization: basicAuth
    },
    method: 'get',
    params: { token }
  })
    .then((response) => {
      if (response.data.code === 303) {
        // 接口303代表该账号已在其他地方登录
        ElMessageBox.confirm(response.data.msg, '错误', {
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          confirmButtonText: '确定',
          type: 'warning'
        })
          .then(() => {
            store.dispatch('LogOut').then(() => {
              // 刷新登录页面，避免多次弹框
              window.location.reload()
            })
          })
          .catch(() => {})
      } else {
        const expire = response && response.data && response.data.exp
        if (expire) {
          const expiredPeriod = expire * 1000 - new Date().getTime()
          // console.log('当前token过期时间', expiredPeriod, '毫秒')
          //小于半小时自动续约
          if (expiredPeriod <= website.remainingTime) {
            if (!refreshLock) {
              refreshLock = true
              $store.dispatch('RefreshToken').catch(() => {
                clearInterval(this.refreshTime)
              })
              refreshLock = false
            }
          }
        }
      }
    })
    .catch((error) => {
      console.error(error)
    })
}

/**
 * 注册用户
 */
export const registerUser = (userInfo) => {
  return request({
    url: '/admin/register/user',
    method: 'post',
    data: userInfo
  })
}
