<template>
  <div class="vue-top">
    <div class="top-bar__left">
      <div :class="[{ 'vue-breadcrumb--active': isCollapse }]" v-if="showCollapse">
        <!-- <i class="icon-navicon"></i> -->
        <el-icon :size="22" @click="setCollapse">
          <Grid />
        </el-icon>
      </div>
    </div>
    <div class="top-bar__title">
      <div class="top-bar__item top-bar__item--show" v-if="showMenu">
        <top-menu></top-menu>
      </div>
    </div>
    <div class="top-bar__right">
      <el-tooltip v-if="showLock" effect="dark" content="锁屏" placement="bottom">
      </el-tooltip>
      <el-tooltip v-if="showFullScren" effect="dark" :content="isFullScreen ? '退出全屏' : '全屏'" placement="bottom">
        <div class="top-bar__item">
          <i :class="isFullScreen ? 'icon-zuixiaohua' : 'icon-quanpingzuidahua'" @click="handleScreen"></i>
        </div>
      </el-tooltip>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          {{ userInfo.username }}
          <el-icon>
            <ArrowDown />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="index">
              <router-link to="/wel/index">首页</router-link>
            </el-dropdown-item>
            <!-- <el-dropdown-item command="userInfo">
              <router-link to="/info/index">个人信息</router-link>
            </el-dropdown-item> -->
            <el-dropdown-item divided command="logout">退出系统
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { fullscreenToggel, listenfullscreen } from '@/util/util'
import topMenu from './top-menu'

export default {
  components: {
    topMenu
  },
  name: 'top',
  data() {
    return {}
  },
  filters: {},
  created() {},
  mounted() {
    listenfullscreen(this.setScreen)
  },
  computed: {
    ...mapState({
      showLock: (state) => state.common.showLock,
      showFullScren: (state) => state.common.showFullScren,
      showCollapse: (state) => state.common.showCollapse,
      showMenu: (state) => state.common.showMenu
    }),
    ...mapGetters([
      'userInfo',
      'isFullScreen',
      'tagWel',
      'tagList',
      'isCollapse',
      'tag',
      'logsLen',
      'logsFlag'
    ])
  },
  methods: {
    handleScreen() {
      fullscreenToggel()
    },
    setCollapse() {
      this.$store.commit('SET_COLLAPSE')
    },
    setScreen() {
      this.$store.commit('SET_FULLSCREN')
    },
    handleCommand(command) {
      if (command === 'logout') [this.logout()]
    },
    logout() {
      this.$confirm('是否退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push({ path: '/login' })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
