<template>
  <template v-for="item in menu" :key="item[labelKey]">
    <el-menu-item v-if="validatenull(item[childrenKey]) && vaildRoles(item)" :index="item[pathKey]" @click="open(item)">
      <component :is="item[iconKey]" style="width: 16px;height: 16px;margin-right: 10px;"></component>
      <span>{{ item[labelKey] }}</span>
    </el-menu-item>
    <el-sub-menu v-else-if="!validatenull(item[childrenKey]) && vaildRoles(item)" :index="item[pathKey]" popper-class="dark-mode">
      <template #title>
        <component :is="item[iconKey]" style="width: 16px;height: 16px;margin-right: 10px;"></component>
        <span :class="{ 'el-menu--display': collapse && first }">{{ item[labelKey] }}</span>
      </template>
      <template v-for="(child, cindex) in item[childrenKey]">
        <el-menu-item :index="child[pathKey]" @click="open(child)" v-if="validatenull(child[childrenKey])" :key="child[labelKey]">
          <!-- <component :is="Aim" style="width: 16px;height: 16px;margin-right: 10px;"></component> -->
          <el-icon :size="16">
            <Help />
          </el-icon>
          <span>{{ child[labelKey] }}</span>
        </el-menu-item>
        <sidebar-item v-else :menu="[child]" :key="cindex" :props="props" :screen="screen" :collapse="collapse">
        </sidebar-item>
      </template>
    </el-sub-menu>
  </template>
</template>
<script>
import { mapGetters } from 'vuex'
import { validatenull } from '@/util/validate'
import config from './config.js'
export default {
  name: 'sidebarItem',
  data() {
    return {
      config: config
    }
  },
  props: {
    menu: {
      type: Array
    },
    screen: {
      type: Number
    },
    first: {
      type: Boolean,
      default: false
    },
    props: {
      type: Object,
      default: () => {
        return {}
      }
    },
    collapse: {
      type: Boolean
    }
  },
  created() {
    console.log(this.menu, 'menu')
  },
  mounted() {},
  components: {},
  computed: {
    ...mapGetters(['roles']),
    labelKey() {
      return this.props.label || this.config.propsDefault.label
    },
    pathKey() {
      return this.props.path || this.config.propsDefault.path
    },
    iconKey() {
      return this.props.icon || this.config.propsDefault.icon
    },
    childrenKey() {
      return this.props.children || this.config.propsDefault.children
    }
    // nowTagValue() {
    //   return this.$router.$avueRouter.getValue(this.$route)
    // }
  },
  methods: {
    vaildRoles(item) {
      item.meta = item.meta || {}
      return item.meta.roles ? item.meta.roles.includes(this.roles) : true
    },
    validatenull(val) {
      return validatenull(val)
    },
    open(item) {
      console.log(item, 'item')
      if (this.screen <= 1) this.$store.commit('SET_COLLAPSE')
      // this.$router.$avueRouter.group = item.group
      this.$router.push({
        path: item.path,
        query: item.query
      })
    }
  }
}
</script>

