<template>
  <div class="vue-contail" :class="{'vue--collapse':isCollapse}">
    <div class="vue-layout">
      <div class="vue-left">
        <Logo></Logo>
        <!-- 左侧导航栏 -->
        <sidebar />
      </div>
      <div class="vue-main">
        <div class="vue-header">
          <!-- 顶部导航栏 -->
          <top />
          <tags />
        </div>
        <!-- 主体视图层 -->
        <el-scrollbar>
          <div class="mod-config">
            <!-- <keep-alive>
              <router-view class="vue-view" v-if="$route.meta.$keepAlive" />
            </keep-alive>
            <router-view class="vue-view" v-if="!$route.meta.$keepAlive" /> -->
            <router-view v-slot="{ Component }">
              <keep-alive v-if="$route.meta.keepAlive">
                <component :is="Component"/>
              </keep-alive>
              <component v-if="!$route.meta.keepAlive" :is="Component"/>
            </router-view>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapGetters } from 'vuex'
import top from './top/'
import Logo from '@/components/Layout/logo.vue'
import tags from '@/components/Layout/tags.vue'
import sidebar from './sidebar'
import admin from '@/util/admin'
import { checkToken } from '@/api/login'
import { useCommonV3Store } from '@/store/modules/commonV3'

export default {
  components: {
    top,
    sidebar,
    Logo,
    tags
  },
  name: 'layoutIndex',
  data() {
    return {
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: ''
    }
  },
  created() {
    //实时检测刷新token
    this.refreshToken()
  },
  unmounted() {
    clearInterval(this.refreshTime)
  },
  mounted() {
    this.init()
  },
  computed: mapGetters(['userInfo', 'isLock', 'isCollapse', 'website']),
  props: [],
  setup() {
    const useCommonV3 = useCommonV3Store()
    useCommonV3.getAirlineList()
  },
  methods: {
    showCollapse() {
      this.$store.commit('SET_COLLAPSE')
    },
    // 屏幕检测
    init() {
      this.$store.commit('SET_SCREEN', admin.getScreen())
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit('SET_SCREEN', admin.getScreen())
        }, 0)
      }
    },
    refreshToken() {
      this.refreshTime = setInterval(() => {
        checkToken(this.refreshLock, this.$store)
      }, 60000)
    }
  }
}
</script>
  