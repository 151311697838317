import Vue from 'vue'
import {  createStore } from 'vuex'
import user from './modules/user'
import common from './modules/common'
import tags from './modules/tags'
import getters from './getters'

export default createStore({
  modules: {
    user,
    common,
    tags
  },
  getters
})

// const store = new Vuex.Store({
//   modules: {
//     user,
//     common,
//     tags
//   },
//   getters
// })
