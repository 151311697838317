<template >
  <el-scrollbar view-style="font-weight: bold;height: calc(100% - 64px);  margin-top: 64px;background-color:#181818;" style="height: 100%;" :native="false">
    <div v-if="validatenull(menu)" class="avue-sidebar--tip">
      没有发现菜单
    </div>
    <el-menu unique-opened :default-active="nowTagValue" router :show-timeout="200" :collapse="keyCollapse" class="el-menu-vertical-demo dark-mode">
      <sidebar-item :menu="menu" :screen="screen" first :props="website.menu.props" :collapse="keyCollapse">
      </sidebar-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import sidebarItem from './sidebarItem'
import { mapGetters } from 'vuex'

export default {
  name: 'sidebarIndex',
  components: { sidebarItem },
  data() {
    return {}
  },
  created() {
    // this.$store.dispatch('GetMenu', { type: true, id: -1 })
  },
  computed: {
    ...mapGetters(['website', 'menu', 'tag', 'keyCollapse', 'screen']),
    nowTagValue: function () {
      return this.$route.path
    }
  },
  mounted() {},
  methods: {}
}
</script>
