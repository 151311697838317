import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from '@/router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import API from './util/axios'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/assets/styles/common.scss'
import { validatenull } from '@/util/validate'
import './permission'
import * as urls from '@/assets/const/env'
// 接口方法
import request from '@/util/request'
import { downBlobFile } from '@/util/util'
import { createPinia } from 'pinia'

const app = createApp(App)

// pinia
const pinia = createPinia()
app.use(pinia)

app.config.globalProperties.$icons = []
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.config.globalProperties.$icons.push(key)
  app.component(key, component)
}

app.use(router)
app.use(store)
app.use(ElementPlus, {
  locale: zhCn,
  size: 'small'
})
// 加载相关url地址
Object.keys(urls).forEach((key) => {
  app.config.globalProperties[key] = urls[key]
})

// 挂载常用全局方法，import 引入
app.config.globalProperties.$http = API
app.config.globalProperties.validatenull = validatenull
app.config.globalProperties.request = request
app.config.globalProperties.downBlobFile = downBlobFile

app.mount('#app')
