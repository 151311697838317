<template>
  <div class="vue-tags" v-if="showTag">
    <!-- tag盒子 -->
    <div v-if="contextmenuFlag" class="vue-tags__contentmenu" :style="{ left: contentmenuX + 'px', top: contentmenuY + 'px' }">
      <div class="item" @click="closeOthersTags">关闭其他</div>
      <div class="item" @click="closeAllTags">关闭全部</div>
    </div>
    <div class="vue-tags__box" :class="{ 'vue-tags__box--close': !website.isFirstPage }">
      <el-tabs v-model="active" type="card" size="small" @contextmenu="handleContextmenu" :closable="tagLen !== 1" @tab-click="openTag" @edit="menuTag">
        <el-tab-pane :key="item.value" v-for="item in tagList" :label="item.label" :name="item.value">
        </el-tab-pane>
      </el-tabs>
      <el-dropdown class="vue-tags__menu">
        <el-button type="primary" size="small">
          更多
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="closeOthersTags">关闭其他</el-dropdown-item>
            <el-dropdown-item @click="closeAllTags">关闭全部</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { getStore, removeStore } from '@/util/store'
import $bus from '@/util/bus'
export default {
  name: 'tagsIndex',
  data() {
    return {
      active: '',
      contentmenuX: '',
      contentmenuY: '',
      contextmenuFlag: false
    }
  },
  created() {
    $bus.on('TAG_DEL', (val) => {
      this.menuTag(val, 'remove')
    })
  },
  mounted() {
    this.setActive()
  },
  unmounted() {
    $bus.off('TAG_DEL')
  },
  watch: {
    tag() {
      this.setActive()
    },
    contextmenuFlag(contextmenuShow) {
      // 只在右键菜单显示的时候监听鼠标点击事件
      if (contextmenuShow) {
        window.addEventListener('mousedown', this.watchContextmenu)
      }
    }
  },
  computed: {
    ...mapGetters(['tagWel', 'tagList', 'tag', 'website']),
    ...mapState({
      showTag: (state) => state.common.showTag
    }),
    tagLen() {
      return this.tagList.length || 0
    }
  },
  methods: {
    watchContextmenu(e) {
      if (!this.$el.contains(e.target)) {
        this.contextmenuFlag = false
        window.removeEventListener('mousedown', this.watchContextmenu)
      }
    },
    handleContextmenu(event) {
      let target = event.target
      // 解决 https://github.com/d2-projects/d2-admin/issues/54
      let flag = false
      if (target.className.indexOf('el-tabs__item') > -1) flag = true
      else if (target.parentNode.className.indexOf('el-tabs__item') > -1) {
        target = target.parentNode
        flag = true
      }
      if (flag) {
        event.preventDefault()
        event.stopPropagation()
        this.contentmenuX = event.clientX
        this.contentmenuY = event.clientY
        this.tagName = target.getAttribute('aria-controls').slice(5)
        this.contextmenuFlag = true
      }
    },
    //激活当前选项
    setActive() {
      this.active = this.tag.value || this.tagWel.value
    },
    menuTag(value, action) {
      if (action === 'remove') {
        let { tag, key } = this.findTag(value)
        this.$store.commit('DEL_TAG', tag)
        // 关闭标签时删除相应的路由缓存
        let query = getStore({ name: tag.value })
        if (query) {
          removeStore({ name: tag.value })
        }
        if (tag.value === this.tag.value) {
          tag = this.tagList[key === 0 ? key : key - 1] //如果关闭本标签让前推一个
          this.openTag(tag)
        }
      }
    },
    openTag(tab) {
      // 切换tab
      let tag
      if (tab.paneName) {
        tag = this.findTag(tab.paneName).tag
      } else {
        tag = tab
      }
      if (tag.value === this.tag.value || this.tag.value === '') {
        return
      }
      this.$router.push({
        path: tag.value,
        query: tag.query
      })
    },
    closeOthersTags() {
      this.contextmenuFlag = false
      let tagList = getStore({ name: 'tagList' })
      tagList.forEach((item) => {
        if (item.value !== this.tag.value) {
          // 关闭标签时删除相应的路由缓存
          let query = getStore({ name: item.value })
          if (query) {
            removeStore({ name: item.value })
          }
        }
      })
      this.$store.commit('DEL_TAG_OTHER')
    },
    findTag(value) {
      let tag, key
      this.tagList.map((item, index) => {
        if (item.value === value) {
          tag = item
          key = index
        }
      })
      return { tag: tag, key: key }
    },
    closeAllTags() {
      this.contextmenuFlag = false
      let tagList = getStore({ name: 'tagList' })
      tagList.forEach((item) => {
        // 关闭标签时删除相应的路由缓存
        let query = getStore({ name: item.value })
        if (query) {
          removeStore({ name: item.value })
        }
      })
      this.$store.commit('DEL_ALL_TAG')
      this.$router.push({
        path: this.tagWel.value,
        query: this.tagWel.query
      })
    }
  }
}
</script>
