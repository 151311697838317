import { validatenull } from './validate'
import request from './axios'
import * as CryptoJS from 'crypto-js'
import { ElMessage } from 'element-plus'

// 数据合并
export function mergeRecursive(source, target) {
  for (let index in target) {
    try {
      if (target[index].constructor === Object) {
        source[index] = mergeRecursive(source[index], target[index])
      } else {
        source[index] = target[index]
      }
    } catch (e) {
      source[index] = target[index]
    }
  }
  return source
}

// 回显数据字典
export function selectDictLabel(datas, value) {
  if (value === undefined) {
    return ''
  }
  let actions = []
  Object.keys(datas).some((key) => {
    if (datas[key].value === '' + value) {
      actions.push(datas[key].label)
      return true
    }
  })
  if (actions.length === 0) {
    actions.push(value)
  }
  return actions.join('')
}

// 表单序列化
export const serialize = (data) => {
  let list = []
  Object.keys(data).forEach((ele) => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}
export const getObjType = (obj) => {
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = (data) => {
  var type = getObjType(data)
  var obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
  delete obj1.close
  var o1 = obj1 instanceof Object
  var o2 = obj2 instanceof Object
  if (!o1 || !o2) {
    /*  判断不是对象  */
    return obj1 === obj2
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
    // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (var attr in obj1) {
    // if (attr === "query" || attr === "params") {
    //   continue;
    // }
    var t1 = obj1[attr] instanceof Object
    var t2 = obj2[attr] instanceof Object
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr])
    } else if (obj1[attr] !== obj2[attr]) {
      return false
    }
  }
  return true
}
/**
 * 设置灰度模式
 */
export const toggleGrayMode = (status) => {
  if (status) {
    document.body.className = document.body.className + ' grayMode'
  } else {
    document.body.className = document.body.className.replace(' grayMode', '')
  }
}
/**
 * 设置主题
 */
export const setTheme = (name) => {
  document.body.className = name
}

/**
 *加密处理
 */
export const encryption = (params) => {
  let { data, type, param, key } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach((ele) => {
      var data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.NoPadding
      })
      result[ele] = encrypted.toString()
    })
  }
  return result
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen()
  } else {
    reqFullScreen()
  }
}
/**
 * esc监听全屏
 */
export const listenfullscreen = (callback) => {
  function listen() {
    callback()
  }

  document.addEventListener('fullscreenchange', function () {
    listen()
  })
  document.addEventListener('mozfullscreenchange', function () {
    listen()
  })
  document.addEventListener('webkitfullscreenchange', function () {
    listen()
  })
  document.addEventListener('msfullscreenchange', function () {
    listen()
  })
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
  return (
    document.isFullScreen ||
    document.mozIsFullScreen ||
    document.webkitIsFullScreen
  )
}

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen()
  }
}
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen()
  }
}
/**
 * 递归寻找子类的父类
 */

export const findParent = (menu, id) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].children.length != 0) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].id == id) {
          return menu[i]
        } else {
          if (menu[i].children[j].children.length != 0) {
            return findParent(menu[i].children[j].children, id)
          }
        }
      }
    }
  }
}

/**
 * 动态插入css
 */

export const loadStyle = (url) => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(link)
}
/**
 * 判断路由是否相等
 */
export const isObjectValueEqual = (a, b) => {
  let result = true
  Object.keys(a).forEach((ele) => {
    const type = typeof a[ele]
    if (type === 'string' && a[ele] !== b[ele]) result = false
    else if (
      type === 'object' &&
      JSON.stringify(a[ele]) !== JSON.stringify(b[ele])
    )
      result = false
  })
  return result
}
/**
 * 根据字典的value显示label
 */
export const findByvalue = (dic, value) => {
  let result = ''
  if (validatenull(dic)) return value
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    let index = 0
    index = findArray(dic, value)
    if (index != -1) {
      result = dic[index].label
    } else {
      result = value
    }
  } else if (value instanceof Array) {
    result = []
    let index = 0
    value.forEach((ele) => {
      index = findArray(dic, ele)
      if (index != -1) {
        result.push(dic[index].label)
      } else {
        result.push(value)
      }
    })
    result = result.toString()
  }
  return result
}
/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
  for (let i = 0; i < dic.length; i++) {
    if (dic[i].value == value) {
      return i
    }
  }
  return -1
}
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len || 4)
  if (date) random = random + Date.now()
  return random
}

/**
 *
 * @param url 目标下载接口
 * @param query 查询参数
 * @param fileName 文件名称
 * @returns {*}
 */
export function downBlobFile(url, query, fileName) {
  return request({
    url: url,
    method: 'get',
    responseType: 'blob',
    params: query
  }).then((response) => {
    // 处理返回的文件流
    const blob = response.data
    if (blob && blob.size === 0) {
      this.$notify.error('内容为空，无法下载')
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    window.setTimeout(function () {
      URL.revokeObjectURL(blob)
      document.body.removeChild(link)
    }, 0)
  })
}

/**
 * select数据在查看页面显示时，按value查找label；multiple是否多选
 */
export function formatSelect(
  val,
  list,
  multiple = false,
  arr = ['value', 'label']
) {
  if (!val) return ''
  if (!list || list.length === 0) return val
  let label = ''
  if (multiple) {
    let labelArr = []
    let valueArr = val.split(',')
    ;(valueArr || []).forEach((v) => {
      if (v || v === 0) {
        let data = list.find((l) => l[arr[0]] === v)
        data ? labelArr.push(data[arr[1]]) : null
      }
    })
    return labelArr.join('，')
  }
  for (let item of list) {
    if (item[arr[0]] === val) {
      label = item[arr[1]]
      return label
    }
  }
  return label
}

/*
 * 处理货币类型转换:
 */
export function formatRate(value, rate) {
  if (value === 0) return 0
  return (Number(value) * rate).toFixed(2)
}
/*
 * 处理小写字母转换为大写字母
 */
export function formatToUpperCase(value) {
  if (!value) return
  return value.toUpperCase()
}

/*
 * 处理中文逗号转成英文逗号
 */
export function formatToComma(value) {
  if (!value) return
  return value.replaceAll('，', ',')
}

/**
 * 处理换行、制表符、空格
 */
export function formatReplace(value) {
  if (!value) return
  // 去除所有换行
  let res = value.replace(/\r\n/g, '').replace(/\n/g, '')
  // 去除所有空格
  res = res.replace(/\s/g, '')
  return res
}

/**
 * 处理小写字母转换，中文逗号转英文，去空格
 * @param {*} value
 * @param {*} comma
 * @param {*} replace
 * @param {*} upperCase
 */
export function formatLetterAll(
  value,
  upperCase = true,
  comma = true,
  replace = true
) {
  let res = value
  if (upperCase) {
    res = formatToUpperCase(res)
  }
  if (comma) {
    res = formatToComma(res)
  }
  if (replace) {
    res = formatReplace(res)
  }
  return res || ''
}

/**
 * 处理航班出发到达时间格式为 2023-05-12 00：10 - 03：30 +1
 */
import moment from 'moment'
import { formatDate, formatDatetime, formatTimeCustom } from '@/util/date'

export function formatFlightDate(departureDate, arrivalDate) {
  if (!departureDate || !arrivalDate) return
  // 处理出发到达时间展示格式，隔天到达显示为+x
  let arrival = moment(arrivalDate).format('yyyy-MM-DD')
  let departure = moment(departureDate).format('yyyy-MM-DD')
  let dayIndex = moment(arrival).diff(departure, 'days')
  let data = `${departure} ${formatTimeCustom(
    departureDate.split(' ')[1]
  )}-${formatTimeCustom(arrivalDate.split(' ')[1])}`
  let day = dayIndex > 0 ? `+${dayIndex}` : ''

  // 判断出发时间的紧急程度，使用不同字体颜色
  let value = departureDate.split(' ')[0]
  let time1 = formatDate(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 1)
  )
  let time2 = formatDate(new Date())
  if (time2 === value) return `<span style="color: red;">${data}${day}</span>`
  if (time1 === value)
    return `<span style="color: orange;">${data}${day}</span>`
  return `<span>${data}${`<span style="color: red;">${day}</span>`}</span>`
}

/**
 * 判断出发时间的紧急程度，使用不同字体颜色
 */
export function judgeTime(departureDate) {
  let value = departureDate.split(' ')[0]
  let time1 = formatDate(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 1)
  )
  let time2 = formatDate(new Date())
  if (time2 === value)
    return `<span style="color: red;">${departureDate}</span>`
  if (time1 === value)
    return `<span style="color: orange;">${departureDate}</span>`
  return `<span>${departureDate}</span>`
}

/**
 * 判断时间超时和即将超时，超时时间字体显示红色，即将超时显示橙色
 * @param {*} datetime 需要判断的时间
 * @param {*} time 时间间隔值，需要判断的时间与当前时间间隔少于该值，则需要高亮提示
 * @returns
 */
export function judgeTimeOut(datetime, time = 30) {
  if (!datetime) return ''
  let value = formatDatetime(datetime)
  let nowDate = formatDatetime(new Date())
  let diffMin = moment(value).diff(nowDate, 'minutes')
  if (diffMin <= 0) {
    return {
      color: 'red'
    }
  } else if (diffMin > 0 && diffMin <= time) {
    return {
      color: 'orange'
    }
  } else {
    return {}
  }
}
/*
 * 将日期转换成星期
 */
export function formatWeek(time) {
  if (!time) return
  let weekList = {
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六'
  }
  return weekList[moment(time).day()]
}
/**
 * 将1-7数值转换成星期，
 *  @param numArr 数值集合
 *
 */
export function numFormatWeek(numArr) {
  if (!numArr.length) return
  let weekList = {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '日'
  }
  let returnArr = []
  numArr.forEach((item) => {
    returnArr.push(weekList[item])
  })
  return returnArr.join('，')
}

/**
 * 复制
 */
export function copy(name, val) {
  var temp = document.createElement('textarea')
  temp.value = val
  document.body.appendChild(temp)
  temp.select()
  let currentFocus = document.activeElement
  if (document.execCommand('copy', true)) {
    ElMessage.success(name + '已复制到粘贴板')
  }
  currentFocus.focus()
  document.body.removeChild(temp)
}
/**
 * 新增或创建提示
 */
export function showAddOrEditMessage(flag = false) {
  if (flag) {
    ElMessage({
      type: 'success',
      message: '修改成功'
    })
  } else {
    ElMessage({
      type: 'success',
      message: '创建成功'
    })
  }
}

/**
 * 出发到达机场转换
 * @param {*} dataForm 数据对象
 * @param {*} depAir 出发机场字段名称
 * @param {*} arrAir  到达机场字段名称
 */
export function handleExchange(dataForm, depAir, arrAir) {
  let depAirport = dataForm[depAir]
  let arrAirport = dataForm[arrAir]
  dataForm[depAir] = arrAirport
  dataForm[arrAir] = depAirport
}

/**
 * 根据activeName过滤订单状态
 * @param {*} orderStatus
 * @param {*} activeName
 * @returns
 */
export function judgeOrderStatus(orderStatus, activeName) {
  if (orderStatus.activeName.findIndex((ac) => ac == activeName) > -1) {
    return true
  }
  return false
}
/**
 * 根据activeName和订单状态过滤子状态
 * @param {*} orderStatus
 * @param {*} orderChildrenStatus
 * @param {*} activeName
 * @returns
 */
export function judgeChildrenOrderStatus(
  orderStatus,
  orderChildrenStatus,
  activeName
) {
  if (!orderStatus) {
    if (
      orderChildrenStatus.activeName.findIndex((ac) => ac == activeName) > -1
    ) {
      return true
    }
    return false
  } else {
    if (
      orderChildrenStatus.orderStatus.findIndex((ac) => ac == orderStatus) >
        -1 &&
      orderChildrenStatus.activeName.findIndex((ac) => ac == activeName) > -1
    ) {
      return true
    }
    return false
  }
}
/**
 * 表格表头换行显示
 */
import { createVNode } from 'vue'
export function renderheader({ column }) {
  return createVNode('span', {}, [
    createVNode('span', {}, column.label.split('/')[0]),
    createVNode('br'),
    createVNode('span', {}, column.label.split('/')[1])
  ])
}
