<template>
  <div class="vue-logo">
    <transition name="fade">
      <span v-if="keyCollapse" class="vue-logo_subtitle" key="0">
        {{website.logo}}
      </span>
    </transition>
    <transition-group name="fade">
      <template v-if="!keyCollapse">
        <span class="vue-logo_title" key="1">{{website.title}} </span>
      </template>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'logoIndex',
  data() {
    return {}
  },
  created() {},
  computed: {
    ...mapGetters(['website', 'keyCollapse'])
  },
  methods: {}
}
</script>

<style lang="scss">
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-active {
  transition: opacity 2.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.vue-logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 180px;
  height: 64px;
  line-height: 64px;
  background-color: #181818;
  font-size: 20px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.8);
  z-index: 1024;
  &_title {
    display: block;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
  }
  &_subtitle {
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }
}
</style>