import store from '@/store'
import router from '@/router'

// 将菜单信息转成对应的路由信息 动态添加
export default function createRoutes() {
  // let routers = []
  let userRouter = store.state.user.menu
  console.log(userRouter, 'userRouter')
  userRouter.forEach((item) => {
    if (item.children.length > 0) {
      let res = JSON.parse(JSON.stringify(item))
      res.component = loadView('Layout')
      res.children = recursiveComponent(res.children)
      // routers.push(res)
      router.addRoute(res)
    } else {
      let res = {
        path: item.path,
        component: loadView('Layout'),
        children: [
          {
            path: '',
            component: loadView(item.componentPath),
            name: item.name,
            meta: {
              title: item.meta.title,
              breadcrumb: item.breadcrumb,
              icon: item.meta.icon
            }
          }
        ]
      }
      router.addRoute(res)
      // routers.push(res)
    }
  })
  // 最后添加404页面 否则会在登陆成功后跳到404页面
  // routers.push({ path: '*', redirect: '/404' })
  router.addRoute({
    path: '/:pathMatch(.*)', // 不识别的path自动匹配404
    component: () =>
      import(/* webpackChunkName: "page" */ '@/components/error-page/404')
  })
  // return routers
}

function loadView(view) {
  // 路由懒加载
  if (view == 'Layout') {
    return () => import(`@/components/Layout/index.vue`)
  }
  return () => import(`@/views${view}.vue`)
}

function recursiveComponent(children) {
  children.forEach((i) => {
    i.component = loadView(i.componentPath)
    i.meta = {
      keepAlive: i.keepAlive,
    }
    if (i.children.length > 0) {
      recursiveComponent(i.children)
    }
  })
  return children
}
