import axios from 'axios'
import { serialize } from '@/util/util'
import NProgress from 'nprogress' // progress bar
import errorCode from '@/assets/const/errorCode'
import { ElMessage } from 'element-plus'
import 'nprogress/nprogress.css'
import qs from 'qs'
import store from '@/store'
import lodash from 'lodash'

axios.defaults.timeout = 120000
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
// NProgress Configuration
NProgress.configure({
  showSpinner: false
})

// HTTPrequest拦截
axios.interceptors.request.use(
  (config) => {
    NProgress.start() // start progress bar
    const isToken = (config.headers || {}).isToken === false
    let token = store.getters.access_token
    if (token && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + token // token
    }
    // if (process.env.NODE_ENV == 'development') {
    //   const devHost = require('@/config/hostmap').dev
    //   var bk = false
    //   for (var ip in devHost) {
    //     if (bk) break
    //     for (var idx in devHost[ip]) {
    //       bk =
    //         devHost[ip][idx] instanceof String &&
    //         devHost[ip][idx].toLowerCase() === config.url.toLowerCase()
    //       if (
    //         bk ||
    //         (devHost[ip][idx] instanceof RegExp &&
    //           devHost[ip][idx].test(config.url))
    //       ) {
    //         config.headers['dev-host'] = ip
    //         break
    //       }
    //     }
    //   }
    //   if (process.env.VUE_APP_HOST) {
    //     config.headers['dev-host'] = process.env.VUE_APP_HOST
    //   }
    // }
    // headers中配置serialize为true开启序列化
    if (config.method === 'post' && config.headers.serialize) {
      config.data = serialize(config.data)
      delete config.data.serialize
    }

    // 处理get的params参数为空值或者Null时不传参
    let params = lodash.pickBy(config.params, (item) => item)
    config.params = params

    // 处理get 请求的数组 springmvc 可以处理
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// HTTPresponse拦截
axios.interceptors.response.use(
  (res) => {
    NProgress.done()
    const status = Number(res.status) || 200
    const message = res.data.msg || errorCode[status] || errorCode['default']

    // 后台定义 424 针对令牌过去的特殊响应码
    if (status === 424) {
      store.dispatch('LogOut').then(() => {
        // 刷新登录页面，避免多次弹框
        window.location.reload()
      })
      // MessageBox.confirm("令牌状态已过期，请点击重新登录", "系统提示", {
      //   confirmButtonText: "重新登录",
      //   cancelButtonText: "取消",
      //   type: "warning"
      // })
      //   .then(() => {
      //     store.dispatch("LogOut").then(() => {
      //       // 刷新登录页面，避免多次弹框
      //       window.location.reload();
      //     });
      //   })
      //   .catch(() => { });
      return
    }

    // 判断是否303已登录其他地方
    if (status === 401 && res.data.code === 303) {
      return res
    }

    if (status !== 200 || res.data.code === 1) {
      ElMessage({
        message: message,
        type: 'error'
      })
      return Promise.reject(message)
    }

    return res
  },
  (error) => {
    NProgress.done()
    return Promise.reject(new Error(error))
  }
)

export default axios
